:root {
  
  /* Main Settings  */
  --main-background-color: linear-gradient(326deg, rgba(2,0,36,1) 0%, rgba(248,234,148,1) 0%, rgba(164,164,244,1) 100%); 

  --text-primary-color: rgb(255, 255, 255);
  --text-secondary-color: rgb(68, 68, 68);

  /* Nav Settings  */
  --nav-link-color: rgb(255, 255, 255);
  --nav-link-hover-color: rgb(87, 87, 87);
  --nav-link-hover-background-color: none;
  --nav-background-color: none;
  --nav-height: 150px;
  --nav-logo-width: 125px;
  --nav-logo-font-size: 16px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: rgb(155, 155, 155);
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(65, 65, 65);
  --mobile-nav-link-hover-color: rgb(29, 29, 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgb(216, 216, 216);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(121, 121, 121);

  /* Minting Area */
  --minting-box-color: #151A1F;
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg,#604ae5 0%,#813eee 100%);
  --minting-button-text-color: white;

}






